import React from 'react';

import '../../assets/css/bootstrap.min.css';
import '../../assets/css/all.min.css';
import '../../assets/css/animate.css';
import '../../assets/css/lightcase.css';
import '../../assets/css/swiper.min.css';
import '../../assets/css/style.css';

class LandingPage extends React.Component {

    // Create the function
    AddLibrary(urlOfTheLibrary) {
      const script = document.createElement('script');
      script.src = urlOfTheLibrary;
      script.async = true;
      document.body.appendChild(script);
    }

    render() {
    return (

      <div data-spy="scroll" className="overflow-auto">
      {/* <!-- preloader start here --> */}
      <div className="preloader">
        <div className="preloader-inner">
          <div className="preloader-icon">
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      {/* <!-- preloader ending here --> */}
      
      {/* <!-- search area --> */}
      <div className="search-area">
        <div className="search-input">
          <div className="search-close">
            <i className="fas fa-times"></i>
          </div>
          <form>
            <input type="text" name="text" placeholder="Search Heare"/>
            <button className="search-btn">
              <span className="serch-icon">
                <i className="fas fa-search"></i>
              </span>
            </button>
          </form>
        </div>
      </div>
      {/* <!-- search area --> */}
    
      {/* <!-- mobile menu area start --> */}
      <div className="mobile-menu">
        <nav className="mobile-header primary-menu d-lg-none">
          <div className="header-logo">
            <a href="index.html" className="logo style-1">
              <img src="assets/images/logo/logo.png" alt="logo"/>
            </a>
          </div>
          <div className="header-bar">
            <span></span>
            <span></span>
            <span></span>
          </div> 
        </nav>
        <nav className="menu">
          <div className="mobile-menu-area d-lg-none">
            <div className="mobile-menu-area-inner" id="scrollbar">
              <div className="mobile-search">
                <div className="search-inner">
                  <input type="text" placeholder="Search Here......"/>
                  <button type="submit">
                    <span>
                      <i className="fas fa-search"></i>
                    </span>
                  </button>
                </div>
              </div>
              <ul className="m-menu">
                <li>
                  <a href="#0" className="active">Home</a>
                  <ul className="m-submenu">
                    <li><a href="index.html">Home Seo</a></li>
                    <li className="active"><a href="index-agency.html">Home Agency</a></li>
                    <li><a href="index-app.html">Home App</a></li>
                    <li><a href="index-host.html">Home Host</a></li>
                    <li><a href="index-vpn.html">Home Vpn</a></li>
                  </ul>
                </li>
                <li>
                  <a href="#0">Pages</a>
                  <ul className="m-submenu">
                    <li>
                      <a href="about.html">About</a>
                    </li>
                    <li>
                      <a href="team.html">Team</a>
                    </li>
                    <li>
                      <a href="services.html">Services</a>
                    </li>
                    <li>
                      <a href="faq.html">Faq Page</a>
                    </li>
                    <li>
                      <a href="pricing.html">Pricing Plan</a>
                    </li>
                    <li>
                      <a href="coming-soon.html">Comming soon</a>
                    </li>
                    <li>
                      <a href="404-page.html">404 page</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Portfolio</a>
                  <ul className="m-submenu">
                    <li>
                      <a href="portfolio-2.html">Portfolio One</a>
                    </li>
                    <li>
                      <a href="portfolio-3.html">Portfolio Two</a>
                    </li>
                    <li>
                      <a href="portfolio-grid.html">Portfolio Three</a>
                    </li>
                    <li>
                      <a href="portfolio-4.html">portfolio Four</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#0">Blog</a>
                  <ul className="m-submenu">
                    <li>
                      <a href="blog-left-sidebar.html">blog Sidebar Left</a>
                    </li>
                    <li>
                      <a href="blog-right-sidebar.html">blog Sidebar Right</a>
                    </li>
                    <li>
                      <a href="blog-single.html">Blog Single</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#0">Shop</a>
                  <ul className="m-submenu">
                    <li><a href="shop-page.html">Shope Page</a></li>
                    <li><a href="shop-single.html">Shope Single</a></li>
                    <li><a href="shop-cart.html">shope Cart</a></li>
                  </ul>
                </li>
                <li>
                  <a href="/#/Test">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      {/* <!-- m obile menu area end --> */}
    
      {/* <!-- header start --> */}
      <header className="d-none d-lg-block">
        <div className="bg-white">
          <div className="container-fluid">
            <div className="header-item">
              <div className="header-logo">
                <a href="index.html"><img src="assets/images/logo/logo.png" alt="logo"/></a>
              </div>
              <div className="header-menu">
                  <nav className="primary-menu">
                    <div className="main-menu-area">
                      <ul className="main-menu">
                        <li>
                          <a href="#0">Home</a>
                          <ul className="submenu">
                            <li><a href="index.html">Home Seo</a></li>
                            <li className="active"><a href="index-agency.html">Home Agency</a></li>
                            <li><a href="index-app.html">Home App</a></li>
                            <li><a href="index-host.html">Home Host</a></li>
                            <li><a href="index-vpn.html">Home Vpn</a></li>
                          </ul>
                        </li>
                        <li>
                          <a href="#">Pages</a>
                          <ul className="submenu">
                            <li>
                              <a href="about.html">About</a>
                            </li>
                            <li>
                              <a href="team.html">Team</a>
                            </li>
                            <li>
                              <a href="services.html">Services</a>
                            </li>
                            <li>
                              <a href="faq.html">Faq Page</a>
                            </li>
                            <li>
                              <a href="pricing.html">Pricing Plan</a>
                            </li>
                            <li>
                              <a href="coming-soon.html">Comming soon</a>
                            </li>
                            <li>
                              <a href="404-page.html">404 page</a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="#">Portfolio</a>
                          <ul className="submenu">
                            <li>
                              <a href="portfolio-2.html">Portfolio One</a>
                            </li>
                            <li>
                              <a href="portfolio-3.html">Portfolio Two</a>
                            </li>
                            <li>
                              <a href="portfolio-grid.html">Portfolio Three</a>
                            </li>
                            <li>
                              <a href="portfolio-4.html">portfolio Four</a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="#">Blog</a>
                          <ul className="submenu">
                            <li>
                              <a href="blog-left-sidebar.html">blog Sidebar Left</a>
                            </li>
                            <li>
                              <a href="blog-right-sidebar.html">blog Sidebar Right</a>
                            </li>
                            <li>
                              <a href="blog-single.html">Blog Single</a>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-holder">
                          <a href="#">Shop</a>
                          <ul className="mega-menu">
                            <li>
                              <span className="submenu-title">Shop Page Layout</span>
                              <ul>
                                <li><a href="shop-page.html">Shope Page</a></li>
                                <li><a href="shop-single.html">Shope Single</a></li>
                                <li><a href="shop-cart.html">shope Cart</a></li>
                                <li><a href="#">List Fullwidth</a></li>
                                <li><a href="#">List Left Sidebar</a></li>
                                <li><a href="#">List Right Sidebar</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <span className="submenu-title">Single Product Style</span>
                              <ul>
                                <li><a href="#">Gallery Left</a></li>
                                <li><a href="#">Gallery Right</a>
                                </li>
                                <li><a href="#">Tab Style Left</a>
                                </li>
                                <li><a href="#">Tab Style Right</a>
                                </li>
                                <li><a href="#">Sticky Left</a></li>
                                <li><a href="#">Sticky Right</a></li>
                              </ul>
                            </li>
                            <li>
                              <span className="submenu-title">Single Product Type</span>
                              <ul>
                                <li><a href="#">Single Product</a></li>
                                <li><a href="#">Single Product Sale</a></li>
                                <li><a href="#">Single Product Group</a>
                                </li>
                                <li><a href="#">Single Product Variable</a>
                                </li>
                                <li><a href="#">Single Product Affiliate</a>
                                </li>
                                <li><a href="#">Single Product Slider</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <span className="submenu-title">Shop Related Pages</span>
                              <ul>
                                <li><a href="#">My Account</a></li>
                                <li><a href="#">Login | Register</a></li>
                                <li><a href="#">Wishlist</a></li>
                                <li><a href="#">Cart</a></li>
                                <li><a href="#">Checkout</a></li>
                                <li><a href="#">Compare</a></li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li>
                        <a href="/#/Test">Contact Us</a>
                        </li>
                      </ul>
                    </div>
                  </nav>
              </div>
              <div className="herder-icon">
                <ul className="search-cart">
                  <li className="cart">
                    <a href="#" className="cart-link">
                      <i className="fa fa-shopping-cart"></i>
                    </a>
                    <div className="cart-content">
                      <div className="cart-item">
                        <div className="cart-img">
                          <a href="#"><img src="assets/images/logo/01.png" alt="cart-imge"/></a>
                        </div>
                        <div className="cart-des">
                          <a href="#">Product Title Hore</a>
                          <p>$20.00</p>
                        </div>
                        <div className="cart-btn">
                          <a href="#"><i className="fa fa-times"></i></a>
                        </div>
                      </div>
                      <div className="cart-item">
                        <div className="cart-img">
                          <a href="#"><img src="assets/images/logo/02.png" alt="cart-imge"/></a>
                        </div>
                        <div className="cart-des">
                          <a href="#">Product Title Hore</a>
                          <p>$20.00</p>
                        </div>
                        <div className="cart-btn">
                          <a href="#"><i className="fa fa-times"></i></a>
                        </div>
                      </div>
                      <div className="cart-bottom">
                        <div className="cart-subtotal">
                          <p>Total: <b className="float-right">$40.00</b></p>
                        </div>
                        <div className="cart-action">
                          <button type="submit" className="button">View cart</button>
                          <button type="submit" className="button">Checkout</button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="search">
                    <a href="#0" className="cart-link">
                      <i className="fa fa-search"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- header end --> */}
    
      {/* <!-- banner area start --> */}
      <section className="banner-area">
        <div className="animate-bubble">
          <div className="bubble a-one"></div>
          <div className="bubble a-three"></div>
          <div className="bubble a-four"></div>
          <div className="bubble a-six"></div>
          <div className="bubble a-eight">
            <img src="assets/images/banner/shape/a3.png" alt="animate"/>
          </div>
          <div className="bubble a-nine">
            <img src="assets/images/banner/shape/a3.png" alt="animate"/>
          </div>
          <div className="bubble a-ten">
            <img src="assets/images/banner/shape/a3.png" alt="animate"/>
          </div>
        </div>
        <div className="banner-position">
          <div className="container">
            <div className="section-wrapper">
              <div className="banner-text">
                <h2><span>Starategic</span> design and technology agency.</h2>
                <p>
                  Compellingly streamline cooperative strategic theme 
                  synergistic process improvements.
                </p>
                <a href="#" className="custom-btn">
                  <span>Read More</span>
                </a>
              </div>
              <div className="banner-thumb">
                <img src="assets/images/banner/banner_bg.png" alt="banner-image"/>
    
                <div className="thumb-left">
                  <img src="assets/images/banner/banner_left.png" alt="banner-image"/>
                </div>
                <div className="thumb-right">
                  <img src="assets/images/banner/banner_right.png" alt="banner-image"/>
                </div>
                <div className="thumb-top">
                  <img src="assets/images/banner/banner_top.png" alt="banner-image"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- banner area ends --> */}
    
      {/* <!-- service area start --> */}
      <div className="service-area">
        <div className="container">
          <div className="section-wrapper">
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                <div className="post-content text-center service-content wow">
                  <div className="post-thumb">
                    <img src="assets/images/service/icon/14.png" alt="service-image"/>
                  </div>
                  <div className="post-text">
                    <h4><a href="#0">Digital Marketing</a></h4>
                    <p>
                      Continually aggregate friction
                      web interfaces thout globaly 
                      interfac without globa.
                    </p>
                  </div>
                </div>
              </div>
    
              <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                <div className="post-content text-center service-content wow">
                  <div className="post-thumb">
                    <img src="assets/images/service/icon/15.png" alt="service-image"/>
                  </div>
                  <div className="post-text">
                    <h4><a href="#0">24/7 Support</a></h4>
                    <p>
                      Continually aggregate friction
                      web interfaces thout globaly 
                      interfac without globa.
                    </p>
                  </div>
                </div>
              </div>
    
              <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                <div className="post-content text-center service-content wow">
                  <div className="post-thumb">
                    <img src="assets/images/service/icon/16.png" alt="service-image"/>
                  </div>
                  <div className="post-text">
                    <h4><a href="#0">Optimal Choice</a></h4>
                    <p>
                      Continually aggregate friction
                      web interfaces thout globaly 
                      interfac without globa.
                    </p>
                  </div>
                </div>
              </div>
    
              <div className="col-xl-3 col-lg-4 col-md-6 col-12">
                <div className="post-content text-center service-content wow">
                  <div className="post-thumb">
                    <img src="assets/images/service/icon/17.png" alt="service-image"/>
                  </div>
                  <div className="post-text">
                    <h4><a href="#0">Business Strategy</a></h4>
                    <p>
                      Continually aggregate friction
                      web interfaces thout globaly 
                      interfac without globa.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- service area ends --> */}
    
      {/* <!-- service one area start --> */}
      <div className="service-one">
        <div className="container">
          <div className="section-wrapper">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-md-12">
                <div className="post-heading">
                  <h6>Why Choose Us</h6>
                  <h3>Get benifits and<br/> advantages your market goal.</h3>
                  <p>
                    Conveniently customize proactive web design service for leveraged
                    interfaces without Globally 
                  </p>
                </div>
                <div className="row">
                  <div className="col-md-6 col-12">
                    <div className="post-content style-one">
                      <div className="post-thumb">
                        <img src="assets/images/service/choose.png" alt="service-image"/>
                      </div>
                      <div className="post-text">
                        <h5><a href="#0">Reporting & Analysis</a></h5>
                        <p>
                          Continually aggregate frictionle interc
                          without globally wellies richard. 
                        </p>
                      </div>
                    </div>
                  </div>
    
                  <div className="col-md-6 col-12">
                    <div className="post-content style-one">
                      <div className="post-thumb">
                        <img src="assets/images/service/choose_1.png" alt="service-image"/>
                      </div>
                      <div className="post-text">
                        <h5><a href="#0">Strulegy Advoisor</a></h5>
                        <p>
                          Continually aggregate frictionle interc
                          without globally wellies richard. 
                        </p>
                      </div>
                    </div>
                  </div>
    
                  <div className="col-md-6 col-12">
                    <div className="post-content style-one">
                      <div className="post-thumb">
                        <img src="assets/images/service/choose_2.png" alt="service-image"/>
                      </div>
                      <div className="post-text">
                        <h5><a href="#0">Strulegy Advoisor</a></h5>
                        <p>
                          Continually aggregate frictionle interc
                          without globally wellies richard. 
                        </p>
                      </div>
                    </div>
                  </div>
    
                  <div className="col-md-6 col-12">
                    <div className="post-content style-one">
                      <div className="post-thumb">
                        <img src="assets/images/service/choose_3.png" alt="service-image"/>
                      </div>
                      <div className="post-text">
                        <h5><a href="#">Strulegy Advoisor</a></h5>
                        <p>
                          Continually aggregate frictionle interc
                          without globally wellies richard. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-8">
                <div className="service-thumb">
                  <img src="assets/images/service/choose_thumb.png" alt="service-image"/>
                  <div className="thumb-left wow">
                    <img src="assets/images/service/choose_left.png" alt="service-image"/>
                  </div>
                  <div className="thumb-right wow">
                    <img src="assets/images/service/choose_right.png" alt="service-image"/>
                  </div>
                  <div className="thumb-medile wow">
                    <img src="assets/images/service/choose_medile.png" alt="service-image"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- service one area ends --> */}
    
      {/* <!-- service two area start --> */}
      <div className="service-two">
        <div className="container">
          <div className="row style-two align-items-center">
            <div className="col-xl-6 col-lg-6 col-12">
              <div className="service-thumb">
                <img src="assets/images/service/reserch_bg.png" className="wow" alt="service-image"/>
                <div className="top-thumb wow">
                  <img src="assets/images/service/reserch_top.png" alt="service-image"/>
                </div>
                <div className="bottom-thumb wow">
                  <img src="assets/images/service/reserch_bottom.png" alt="service-image"/>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-12">
              <div className="post-heading">
                <h6>Research</h6>
                <h3>We can give perfect solution for your business.</h3>
                <p>
                  Conveniently customize proactive web but services for leveragsr 
                  interfaces without globally continually aggregate ofte frictionlent 
                  without globally wellies richard.  
                </p>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="post-content style-one">
                    <div className="post-thumb">
                      <img src="assets/images/service/choose.png" alt="service-image"/>
                    </div>
                    <div className="post-text">
                      <h5><a href="#0">Strulegy Advoisor</a></h5>
                      <p>
                        Continually aggregate frictionle interc
                        without globally wellies richard. 
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="post-content style-one">
                    <div className="post-thumb">
                      <img src="assets/images/service/choose_2.png" alt="service-image"/>
                    </div>
                    <div className="post-text">
                      <h5><a href="#0">Strulegy Advoisor</a></h5>
                      <p>
                        Continually aggregate frictionle interc
                        without globally wellies richard. 
                      </p>
                    </div>
                  </div>
                  <a href="#" className="custom-btn">
                    <span>Red More</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- service two area start --> */}
    
      {/* <!-- landing page videos --> */}
      <div className="landing-video-area">
        <div className="animate-bubble style-1">
          <div className="bubble a-one"></div>
          <div className="bubble a-three"></div>
          <div className="bubble a-four"></div>
          <div className="bubble a-six"></div>
          <div className="bubble a-eight">
            <img src="assets/images/banner/shape/a3.png" alt="animate"/>
          </div>
          <div className="bubble a-nine">
            <img src="assets/images/banner/shape/a3.png" alt="animate"/>
          </div>
          <div className="bubble a-ten">
            <img src="assets/images/banner/shape/a3.png" alt="animate"/>
          </div>
        </div>
        <div className="container">
          <div className="video-thumb text-center wow">
            <img src="assets/images/videos/video.jpg" alt="landing-imge"/>
            <div className="thumb-inner">
              <a href="https://www.youtube.com/embed/EctzLTFrktc" data-rel="lightcase" className="video-icon">
                <i className="fas fa-play-circle"></i>
              </a>
              <div className="video-wrapper">
                <div className="wave-pulse wave-pulse-1"></div>
                <div className="wave-pulse wave-pulse-2"></div>
                <div className="wave-pulse wave-pulse-3"></div>
                <div className="wave-pulse wave-pulse-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- landing page videos --> */}
    
      {/* <!-- portfolio slide area start --> */}
      <div className="portfolio-slide-area">
        <div className="container">
          <div className="protfolio-heder text-center section-heading wow">
            <span>Portfolio</span>
            <h3>Our case studies</h3>
            <p>
              Conveniently customize proactive web services 
              for leveraged interfaces without Globally 
            </p>
          </div>
          <div className="portfolio-wrapper">
            <div className="portfolio-container">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="portfolio-content">
                    <div className="portfolio-thumb">
                      <img src="assets/images/portfolio/portfolio.jpg" alt="portfolio-image"/>
                    </div>
                    <div className="portfolio-text">
                      <h5>Report & Analysis</h5>
                      <p>Generation Strategic Theme Areas </p>
                    </div>
                  </div>
                </div>
    
                <div className="swiper-slide">
                  <div className="portfolio-content">
                    <div className="portfolio-thumb">
                      <img src="assets/images/portfolio/portfolio_1.jpg" alt="portfolio-image"/>
                    </div>
                    <div className="portfolio-text">
                      <h5>Business And Consulting</h5>
                      <p>Generation Strategic Theme Areas </p>
                    </div>
                  </div>
                </div>
    
                <div className="swiper-slide">
                  <div className="portfolio-content">
                    <div className="portfolio-thumb">
                      <img src="assets/images/portfolio/portfolio_2.jpg" alt="portfolio-image"/>
                    </div>
                    <div className="portfolio-text">
                      <h5>Report & Analysis</h5>
                      <p>Generation Strategic Theme Areas </p>
                    </div>
                  </div>
                </div>
    
                <div className="swiper-slide">
                  <div className="portfolio-content">
                    <div className="portfolio-thumb">
                      <img src="assets/images/portfolio/portfolio_1.jpg" alt="portfolio-image"/>
                    </div>
                    <div className="portfolio-text">
                      <h5>Strulegy Advoisor</h5>
                      <p>Generation Strategic Theme Areas </p>
                    </div>
                  </div>
                </div>
    
                <div className="swiper-slide">
                  <div className="portfolio-content">
                    <div className="portfolio-thumb">
                      <img src="assets/images/portfolio/portfolio.jpg" alt="portfolio-image"/>
                    </div>
                    <div className="portfolio-text">
                      <h5>Business And Consulting</h5>
                      <p>Generation Strategic Theme Areas </p>
                    </div>
                  </div>
                </div>
    
                <div className="swiper-slide">
                  <div className="portfolio-content">
                    <div className="portfolio-thumb">
                      <img src="assets/images/portfolio/portfolio_2.jpg" alt="portfolio-image"/>
                    </div>
                    <div className="portfolio-text">
                      <h5>Strulegy Advoisor</h5>
                      <p>Generation Strategic Theme Areas </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Add Pagination --> */}
                <div className="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- portfolio slide area start --> */}
    
      {/* <!-- pricing area start --> */}
      <div className="pricing-area">
        <div className="container">
          <div className="section-heading text-center wow">
            <span>Pricing</span>
            <h3>Our best pricing plan</h3>
            <p>
              Conveniently customize proactive web services for leveraged
              interfaces without Globally 
            </p>
          </div>
          <div className="pricing-wrapper">
            <div className="row justify-content-center">
              <div className="col-xl-4 col-md-6 col-12">
                <div className="price-item text-center price-style wow">
                  <div className="price-thumb">
                    <img src="assets/images/pricing/price_shap.png" alt="pricing-image"/>
                    <div className="price_t">
                      <h3>Basic</h3>
                      <p><span>$29</span>Monthly</p>
                    </div>
                    <p>
                      Quisque viverra elit sit amet 
                      hendrerit Sed libero vitae metus
                    </p>
                  </div>
                  <div className="price-list">
                    <ul>
                      <li>Integer comodo massa</li>
                      <li>Nam siewet amet leoing maximus</li>
                      <li>Dignism elit eu viverra elit</li>
                      <li>Nam siewet amet leoing maximus</li>
                    </ul>
                    <a href="#" className="custom-btn">
                      <span>Read More</span>
                    </a>
                  </div>
                </div>
              </div>
    
              <div className="col-xl-4 col-md-6 col-12">
                <div className="price-item text-center price-style wow">
                  <div className="price-thumb">
                    <img src="assets/images/pricing/price_shap.png" alt="pricing-image"/>
                    <div className="price_t">
                      <h3>Standard</h3>
                      <p><span>$49</span>Monthly</p>
                    </div>
                    <p>
                      Quisque viverra elit sit amet 
                      hendrerit Sed libero vitae metus
                    </p>
                  </div>
                  <div className="price-list">
                    <ul>
                      <li>Integer comodo massa</li>
                      <li>Nam siewet amet leoing maximus</li>
                      <li>Dignism elit eu viverra elit</li>
                      <li>Nam siewet amet leoing maximus</li>
                    </ul>
                    <a href="#" className="custom-btn">
                      <span>Read More</span>
                    </a>
                  </div>
                </div>
              </div>
    
              <div className="col-xl-4 col-md-6 col-12">
                <div className="price-item text-center price-style wow">
                  <div className="price-thumb">
                    <img src="assets/images/pricing/price_shap.png" alt="pricing-image"/>
                    <div className="price_t">
                      <h3>Premium</h3>
                      <p><span>$99</span>Monthly</p>
                    </div>
                    <p>
                      Quisque viverra elit sit amet 
                      hendrerit Sed libero vitae metus
                    </p>
                  </div>
                  <div className="price-list">
                    <ul>
                      <li>Integer comodo massa</li>
                      <li>Nam siewet amet leoing maximus</li>
                      <li>Dignism elit eu viverra elit</li>
                      <li>Nam siewet amet leoing maximus</li>
                    </ul>
                    <a href="#" className="custom-btn">
                      <span>Red More</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- pricing area ennds --> */}
    
      {/* <!-- newsletter  area start --> */}
      <div className="newsletter-area">
        <div className="container">
          <div className="news-heading text-center">
            <span>Subscribe our newsletter to get more update.</span>
            <h2>Went to Get Started</h2>
          </div>
          <div className="newsletter-content">
            <form action="0">
              <input type="text" className="news-input" placeholder="Enter Your Email"/>
              <button type="button" className="button">Subscribe</button>
            </form>
          </div>
        </div>
      </div>
      {/* <!-- newsletter  area ends --> */}
    
      {/* <!-- testimonial area start --> */}
      <div className="testimonil-area">
        <div className="container">
          <div className="section-wrapper">
            <div className="test-content">
              <div className="content-inner">
                <span>What Client Say</span>
                <h3>Check testimonials for our satisfied clients</h3>
                <p>
                  Conveniently customize proactive web services interfaces 
                  without Globally Continually aggregate frictionle interc
                  aithout alobally wellies richard. 
                </p>
              </div>
            </div>
            
            <div className="test-wrapper">
              <div className="testimonial-slider">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="test-item">
                      <div className="item-inner">
                        <div className="test-thumb">
                          <img src="assets/images/testimonial/01.png" alt="test-image"/>
                        </div>
                        <div className="test-text">
                          <h6>Yesing Ar</h6>
                          <p>Fr-end Designer</p>
                        </div>
                      </div>
                      <div className="test-decs">
                        <p>
                          Conveniently iterate technically sound internal 
                          sources with corporate potentialities uniquely 
                          maximize business leadership skills. 
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="test-item">
                      <div className="item-inner">
                        <div className="test-thumb">
                          <img src="assets/images/testimonial/05.png" alt="test-image"/>
                        </div>
                        <div className="test-text">
                          <h6>Max Smith</h6>
                          <p>Ui/Ux Designer</p>
                        </div>
                      </div>
                      <div className="test-decs">
                        <p>
                          Conveniently iterate technically sound internal 
                          sources with corporate potentialities uniquely 
                          maximize business leadership skills. 
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="test-item">
                      <div className="item-inner">
                        <div className="test-thumb">
                          <img src="assets/images/testimonial/03.png" alt="test-image"/>
                        </div>
                        <div className="test-text">
                          <h6>Farin Ar</h6>
                          <p>Anim Designer</p>
                        </div>
                      </div>
                      <div className="test-decs">
                        <p>
                          Conveniently iterate technically sound internal 
                          sources with corporate potentialities uniquely 
                          maximize business leadership skills. 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Add Pagination --> */}
                <div className="testi-pagination">
                  <div><span></span></div>
                  <div><span></span></div>
                  <div><span></span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- testimonial area ends --> */}
      
      {/* <!-- sponsor area start --> */}
      <div className="sponsor-section">
        <div className="container">
          <div className="sponsor-wrapper">
            <div className="sponsor-container">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="sponsor-thumb">
                    <div className="thumb-inner">
                      <a href="#" className="sponsor-logo">
                        <span className="before-image"><img src="assets/images/sponsor/sponsor.png" alt="sponsor-imge"/></span>
                        <span className="after-image"><img src="assets/images/sponsor/sponsor.png" alt="sponsor-imge"/></span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="sponsor-thumb">
                    <div className="thumb-inner">
                      <a href="#" className="sponsor-logo">
                        <span className="before-image"><img src="assets/images/sponsor/sponsor_1.png" alt="sponsor-imge"/></span>
                        <span className="after-image"><img src="assets/images/sponsor/sponsor_1.png" alt="sponsor-imge"/></span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="sponsor-thumb">
                    <div className="thumb-inner">
                      <a href="#" className="sponsor-logo">
                        <span className="before-image"><img src="assets/images/sponsor/sponsor_2.png" alt="sponsor-imge"/></span>
                        <span className="after-image"><img src="assets/images/sponsor/sponsor_2.png" alt="sponsor-imge"/></span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="sponsor-thumb">
                    <div className="thumb-inner">
                      <a href="#" className="sponsor-logo">
                        <span className="before-image"><img src="assets/images/sponsor/sponsor_3.png" alt="sponsor-imge"/></span>
                        <span className="after-image"><img src="assets/images/sponsor/sponsor_3.png" alt="sponsor-imge"/></span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="sponsor-thumb">
                    <div className="thumb-inner">
                      <a href="#" className="sponsor-logo">
                        <span className="before-image"><img src="assets/images/sponsor/sponsor.png" alt="sponsor-imge"/></span>
                        <span className="after-image"><img src="assets/images/sponsor/sponsor.png" alt="sponsor-imge"/></span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="sponsor-thumb">
                    <div className="thumb-inner">
                      <a href="#" className="sponsor-logo">
                        <span className="before-image"><img src="assets/images/sponsor/sponsor_1.png" alt="sponsor-imge"/></span>
                        <span className="after-image"><img src="assets/images/sponsor/sponsor_1.png" alt="sponsor-imge"/></span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="sponsor-thumb">
                    <div className="thumb-inner">
                      <a href="#" className="sponsor-logo">
                        <span className="before-image"><img src="assets/images/sponsor/sponsor_2.png" alt="sponsor-imge"/></span>
                        <span className="after-image"><img src="assets/images/sponsor/sponsor_2.png" alt="sponsor-imge"/></span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="sponsor-thumb">
                    <div className="thumb-inner">
                      <a href="#" className="sponsor-logo">
                        <span className="before-image"><img src="assets/images/sponsor/sponsor_3.png" alt="sponsor-imge"/></span>
                        <span className="after-image"><img src="assets/images/sponsor/sponsor_3.png" alt="sponsor-imge"/></span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="sponsor-thumb">
                    <div className="thumb-inner">
                      <a href="#" className="sponsor-logo">
                        <span className="before-image"><img src="assets/images/sponsor/sponsor.png" alt="sponsor-imge"/></span>
                        <span className="after-image"><img src="assets/images/sponsor/sponsor.png" alt="sponsor-imge"/></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- sponsor area end --> */}
    
      {/* <!-- footer area start --> */}
      <footer>
        <div className="footer-position">
          <div className="animate-bubble style-1 style-2">
            <div className="bubble a-one"></div>
            <div className="bubble a-three"></div>
            <div className="bubble a-four"></div>
            <div className="bubble a-six"></div>
            <div className="bubble a-eight">
              <img src="assets/images/banner/shape/a3.png" alt="animate"/>
            </div>
            <div className="bubble a-nine">
              <img src="assets/images/banner/shape/a3.png" alt="animate"/>
            </div>
            <div className="bubble a-ten">
              <img src="assets/images/banner/shape/a3.png" alt="animate"/>
            </div>
            <div className="bubble a-eleven">
              <img src="assets/images/banner/shape/a3.png" alt="animate"/>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                <div className="footer-media">
                  <a href="#">
                    <img src="assets/images/logo/agency.png" alt="footer-image"/>
                    <h4>Halci <span>Agency</span></h4>
                  </a>
                  <p>
                    Conveniently customizec web services 
                    Sontinually aggregate frictionle interfa
                    without Globally without Globally.
                  </p>
                  <ul className="footer-social">
                    <li>
                      <a href="#"><i className="fab fa-facebook-f"></i></a>
                    </li>
                    <li>
                      <a href="#"><i className="fab fa-twitter"></i></a>
                    </li>
                    <li>
                      <a href="#"><i className="fab fa-instagram"></i></a>
                    </li>
                    <li>
                      <a href="#"><i className="fab fa-linkedin-in"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
              
              <div className="col-xl-3 col-lg-3 col-md-6  col-12">
                <div className="footer-contact">
                  <h4>Contact Us</h4>
                  <ul>
                    <li>
                      <i className="fas fa-map-marker-alt"></i>
                      <span>Sector # 48,123 Street,  Australia</span>
                    </li>
                    <li>
                      <i className="fas fa-phone"></i>
                      <span>+88978-658-125, 0078-98-954,</span>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <span>halciagency@gmail.com</span>
                    </li>
                  </ul>
                </div>
              </div>
    
              <div className="col-xl-3 col-lg-3 col-md-6  col-12">
                <div className="link-us">
                  <h4>Quick Link</h4>
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">Pages</a>
                    </li>
                    <li>
                      <a href="#">Portfolio</a>
                    </li>
                    <li>
                      <a href="#">Blog</a>
                    </li>
                  </ul>
                </div>
              </div>
    
              <div className="col-xl-3 col-lg-3 col-md-6  col-12">
                <div className="footer-news">
                  <h4>newsletter</h4>
                  <form action="#0">
                    <input type="text" className="email" placeholder="Enter Email Address"/>
                    <button type="submit" className="button">
                      Subscribe
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      
      <div className="footer-bottom">
        <div className="container">
          <div className="copy-right text-center">
            <p>&copy; 2019 <a href="index.html">Halci.</a> Rights Reserved By <a href="https://themeforest.net/user/labartisan" target="_blank">LabArtisan</a></p>
          </div>
        </div>
      </div>
      {/* <!-- footer area ends --> */}
    
      {/* <!--scroll up--> */}
      <div className="scroll-top">
        <div className="scrollToTop active">
          <span>
            <i className="fas fa-arrow-up"></i>
          </span>
        </div>
      </div>
      {/* <!--scroll up--> */}
      

      {/* <!-- Optional JavaScript --> */}
      {/* <!-- jQuery first, then Bootstrap JS, then font-awosame, then lightcase Js, then image loaded & isotope Js, than swiper js --> */}
      { this.AddLibrary("/assets/js/jquery.js") }
      { this.AddLibrary("/assets/js/bootstrap.min.js") }
      { this.AddLibrary("/assets/js/fontawesome.min.js") }
      { this.AddLibrary("/assets/js/jquery.waypoints.min.js") }
      { this.AddLibrary("/assets/js/imagesloaded.pkgd.min.js") }
      { this.AddLibrary("/assets/js/jquery.counterup.min.js") }
      { this.AddLibrary("/assets/js/count-down.js") }
      { this.AddLibrary("/assets/js/isotope-min.js") }
      { this.AddLibrary("/assets/js/lightcase.js") }
      { this.AddLibrary("/assets/js/swiper.min.js") }
      { this.AddLibrary("/assets/js/theia-sticky-sidebar.js") }
      { this.AddLibrary("/assets/js/wow.min.js") }
      { this.AddLibrary("/assets/js/active.js") }

      </div>

      );
  }

}

export default LandingPage;
