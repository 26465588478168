import logo from './logo.svg';
import './App.css';

import { HashRouter, Routes, Route } from 'react-router-dom';

import LandingPage from './pages/landing-page/LandingPage';
import NotFoundPage from './pages/not-found-page/NotFoundPage';

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/Test" element={<NotFoundPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
  </HashRouter>
);
}

export default App;
