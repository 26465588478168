import React from 'react';

import '../../assets/css/bootstrap.min.css';
import '../../assets/css/all.min.css';
import '../../assets/css/animate.css';
import '../../assets/css/lightcase.css';
import '../../assets/css/swiper.min.css';
import '../../assets/css/style.css';


class NotFoundPage extends React.Component {

    // Create the function
    AddLibrary(urlOfTheLibrary) {
        const script = document.createElement('script');
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
      }
  

    render() {
    return (
        <div data-spy="scroll" className="p-0 overflow-auto">

        <div className="preloader">
            <div className="preloader-inner">
                <div className="preloader-icon">
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
        
        <div className="Not_page">
            <div className="container">
                <div className="images">
                    <img src="assets/images/404/404.png" alt="404"/>
                </div>
                <div className="text-content text-center">
                    <h3>Oops, sorry we can’t find that page</h3>
                    <a href="index.html" className="custom-btn"><span>Back to Home</span></a>
                </div>
            </div>
        </div>


      {/* <!-- Optional JavaScript --> */}
      {/* <!-- jQuery first, then Bootstrap JS, then font-awosame, then lightcase Js, then image loaded & isotope Js, than swiper js --> */}
      { this.AddLibrary("/assets/js/jquery.js") }
      { this.AddLibrary("/assets/js/bootstrap.min.js") }
      { this.AddLibrary("/assets/js/fontawesome.min.js") }
      { this.AddLibrary("/assets/js/jquery.waypoints.min.js") }
      { this.AddLibrary("/assets/js/imagesloaded.pkgd.min.js") }
      { this.AddLibrary("/assets/js/jquery.counterup.min.js") }
      { this.AddLibrary("/assets/js/count-down.js") }
      { this.AddLibrary("/assets/js/isotope-min.js") }
      { this.AddLibrary("/assets/js/lightcase.js") }
      { this.AddLibrary("/assets/js/swiper.min.js") }
      { this.AddLibrary("/assets/js/theia-sticky-sidebar.js") }
      { this.AddLibrary("/assets/js/wow.min.js") }
      { this.AddLibrary("/assets/js/active.js") }

        </div>    
    );
  }

}

export default NotFoundPage;
